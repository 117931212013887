<template>
  <div class="mt-3 table-responsive-sm">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col" class="bb-none text-center text-info">
            <img
              src="@/assets/img/teams_icon.png"
              alt="Teams"
              width="30"
              height="30"
            />
          </th>
          <th
            scope="col"
            class="bb-none text-center"
            v-for="team in tgrid"
            :key="team.ID"
          >
            <img
              :src="team.filetime ? ($url+'upload/images/teams/'+ team.id_team_sl +'/100x100.png?v='+ team.filetime) : '/img/icons/stub.png'"
              width="32"
              height="32"
              class="rounded-sm"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="team in tgrid" :key="team.id_team_sl">
          <th scope="row" class="text-center align-middle">
            <img
              :src="team.filetime ? ($url+'upload/images/teams/'+ team.id_team_sl +'/100x100.png?v='+ team.filetime) : '/img/icons/stub.png'"
              width="32"
              height="32"
              class="rounded-sm"
            />
          </th>
          <td
            class="text-center align-middle"
            v-for="team2 in tgrid"
            :key="team2.id_team_sl"
          >
            <span
              v-if="team.id_team_sl === team2.id_team_sl"
              class="align-middle text-white"
              >///</span
            >
            <div v-else class="d-block align-middle">
              <div
                v-for="stage in sgrid"
                :key="stage.id"
                class="container py-0 px-2"
              >
                <GridGamesScore
                  v-if="stage.id === 13"
                  :games="stage.games"
                  :idteam1="team.id_team_sl"
                  :idteam2="team2.id_team_sl"
                  :dataSport="dataSport"
                ></GridGamesScore>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "TourGrid",
  props: ["sgrid", "tgrid", "dataSport"],
  components: {
    GridGamesScore: () =>
      import("@/components/blocks/rocket_league/GridGamesScore.vue")
  }
};
</script>

<style scoped>
.table {
  border-bottom: 1px solid #dee2e6 !important;
}
.table td,
.table th {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
</style>
